<template>
  <main class="layout-hasColG">
    <header class="layer-primary">
      <div class="main-img main-img-random">
        <img loading="lazy" src="https://cdn.maine-et-loire.fr/ecosysteme_ressources/img/random_une.png" alt="" width="1800" height="650" />
      </div>
    </header>

    <section class="section-content" id="section-content">
      <header>
        <nav aria-label="Breadcrumb">
          <ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
            <li>
              <router-link :to="{ name: 'accueil' }">Accueil</router-link>
            </li>

            <li>Accessibilité et qualité web</li>
          </ul>
        </nav>
        <h1>Accessibilité et qualité web <span></span></h1>

        <p class="intro" id="introPage">
          L'article 47 de la loi n° 2005-102 du 11 février 2005 pour l’égalité des droits et des chances, la participation et la citoyenneté des personnes handicapées fait de l’accessibilité une exigence pour tous les services de communication
          publique en ligne de l’État, les collectivités territoriales et les établissements publics qui en dépendent. Il stipule que les informations diffusées par ces services doivent être accessibles à tous.
        </p>
        <div class="toolbox">
          <p id="tempsLecture">
            <span class="fa fa-solid fa-book-reader"></span>

            Temps de lecture&nbsp;:

            <span id="dureeLecture">2</span>&nbsp;min.
          </p>
        </div>
      </header>

      <div>
        <aside class="sidebar-secondary">
          <nav class="page-nav" id="page-nav" aria-label="Sommaire de la page">
            <span>sommaire</span>
            <ul>
              <li>
                <a href="#c5170" data-titleid="c5170"><span>Référentiels</span></a>
              </li>
              <li>
                <a href="#c5504" data-titleid="c5504"><span>Schéma pluriannuel et plan annuel du Département de Maine-et-Loire</span></a>
              </li>
              <li>
                <a href="#c5503" data-titleid="c5503"><span>Déclaration de conformité - RGAA 4.1</span></a>
              </li>
              <li>
                <a href="#c5163" data-titleid="c5163"><span>Accessibilité des contenus et respect des standards web</span></a>
              </li>
              <li>
                <a href="#c5161" data-titleid="c5161"><span>Tests</span></a>
              </li>
              <li>
                <a href="#c5158" data-titleid="c5158"><span>Retour d'information et contact</span></a>
              </li>
            </ul>
          </nav>
        </aside>
        <article id="main-content">
          <h2 id="c5170">Référentiels</h2>
          <p>
            Le site assmat.maine-et-loire.fr est en cours d’amélioration afin de le rendre conforme au RGAA v4.1 (<a
              href="https://accessibilite.numerique.gouv.fr/"
              title="Référentiel général d’amélioration de l’accessibilité documents officiels - Nouvelle fenêtre"
              target="_blank"
              rel="noopener noreferrer"
              >Référentiel général d’amélioration de l’accessibilité</a
            >).<br /><br />
            Pour apporter toujours plus de services aux usagers, nous misons également sur la qualité du site assmat.maine-et-loire.fr en s'appuyant sur les règles Opquast V4 avec
            <a href="https://checklists.opquast.com/fr/qualiteweb/" title="la checklist des règles de la qualité web - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer">la checklist des règles de la qualité web</a>. Un audit interne est en
            cours de réalisation pour améliorer la qualité du site, le résultat sera publié.
          </p>

          <h2 id="c5504">Schéma pluriannuel et plan annuel du Département de Maine-et-Loire</h2>
          <p>Le Département de Maine-et-Loire s’engage à rendre ses sites internet, intranet, extranet et ses applications mobiles, etc. accessibles conformément à l’article 47 de la loi <abbr title="numéro">n°</abbr>2005-102 du 11 février 2005.</p>
          <p>À cette fin, il met en œuvre la stratégie et les actions suivantes :</p>
          <ul>
            <li>
              <a
                href="https://www.maine-et-loire.fr/accessibilite-et-qualite-web/schema-pluriannuel-daccessibilite-numerique"
                title="Consulter le schéma pluriannuel d'accessibilité 2021-2023 - Nouvelle fenêtre"
                target="_blank"
                rel="noopener noreferrer"
                >consulter le schéma pluriannuel d'accessibilité 2021-2023</a
              >,
            </li>
            <li>
              <a href="https://www.maine-et-loire.fr/accessibilite-et-qualite-web/plan-annuel-daccessibilite-2021" title="Consulter le plan d’action de l’année 2021 - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer"
                >consulter le plan d’action de l’année 2021</a
              >.
            </li>
            <li>
              <a href="https://www.maine-et-loire.fr/accessibilite-et-qualite-web/plan-annuel-daccessibilite-2022" title="Consulter le plan d’action de l'année 2022 - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer"
                >consulter le plan d’action de l’année 2022</a
              >.
            </li>
          </ul>
          <h2 id="c5503">Déclaration de conformité - RGAA 4.1</h2>
          <p>
            Le site <strong>assmat.maine-et-loire.fr</strong> est actuellement non conforme car il n’existe aucun résultat d’audit permettant de mesurer le respect des critères. L’audit de conformité est en cours de planification. Des travaux
            d’amélioration seront planifiés à la suite d’un premier diagnostic.
          </p>

          <h2 id="c5163">Accessibilité des contenus et respect des standards web</h2>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <p>Les normes d'accessibilité numérique incluent les conditions suivantes :</p>
              <ul>
                <li>les tailles des polices de caractère peuvent être agrandies et réduites,</li>
                <li>les images présentent, lorsqu'elles le nécessitent, des textes alternatifs,</li>
                <li>les changements de langue et les abréviations et acronymes sont signalés,</li>
                <li>les titres sont établis sur plusieurs niveaux,</li>
                <li>il est possible de naviguer sur ce site sans utiliser la souris à l'aide de la navigation par tabulation,</li>
                <li>les pages sont correctement structurées et uniformes, tant sur le graphisme que sur la ligne éditoriale,</li>
                <li>les pages sont organisées pour être tout à fait fonctionnelles, même si votre navigateur ne supporte pas le "JavaScript",</li>
                <li>les formulaires sont accessibles à partir de tout support (ordinateur de bureau, tablette, téléphone portable),</li>
                <li>
                  l'usage de feuilles de style assure la séparation de la mise en forme du site et de son contenu. Cela permet de conserver un ordre de lecture cohérent dans la structure de chaque page pour permettre une navigation effective si
                  l'utilisation des CSS est désactivée,
                </li>
                <li>
                  le code HTML est basé sur la spécification adoubée par le W3C (recommandations du 28 octobre 2014) et sur les recommandations du Référentiel général d'accessibilité des administrations (RGAA). Il est testé à l'aide du validateur
                  HTML du W3C,
                </li>
                <li>le code CSS est conforme à la Spécification CSS3. Il est testé à l’aide du validateur CSS du W3C.</li>
              </ul>
            </div>
          </div>

          <div class="essential">
            <h2>Dérogations</h2>
            <p>Les liens sont volontairement ouverts dans une nouvelle fenêtre lorsqu’ils pointent vers un autre site que assmat.maine-et-loire.fr ou lors de l’ouverture d'un fichier <abbr title="Portable Document Format">PDF</abbr>.</p>
          </div>

          <h2 id="c5161">Tests</h2>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <p>Ce site a été testé avec :</p>
              <ul>
                <li>Internet Explorer® 11</li>
                <li>Microsoft Edge 42</li>
                <li>Firefox® 68.11.0 ESR</li>
                <li>Google Chrome® 84</li>
                <li>dans un environnement Windows® 10 Professionnel</li>
              </ul>
              <p>Pour le site dans sa version responsive, il a été testé avec :</p>
              <ul>
                <li>Android 4.4.2</li>
                <li>Iphone iOS 13.2.3</li>
              </ul>
            </div>
          </div>

          <h2 id="c5158">Retour d'information et contact</h2>
          <p>Si vous n’arrivez pas à accéder à un contenu ou à un service, vous pouvez contacter le responsable du site internet pour être orienté vers une alternative accessible ou obtenir le contenu sous une autre forme.</p>
          <ul>
            <li>
              <a href="mailto:numerique@maine-et-loire.fr" title="Contacter les référents de l'accessibilité numérique par courriel - Fenêtre de messagerie">Contacter les référents de l'accessibilité numérique par courriel</a> -
              numerique@maine-et-loire.fr
            </li>
          </ul>
          <h3>Voies de recours</h3>
          <p>Cette procédure est à utiliser dans le cas suivant.</p>
          <p>Vous avez signalé au responsable du site internet un défaut d’accessibilité qui vous empêche d’accéder à un contenu ou à un des services du portail et vous n’avez pas obtenu de réponse satisfaisante.</p>
          <ul>
            <li>
              <a href="https://formulaire.defenseurdesdroits.fr/" title="Écrire un message au Défenseur des droits - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer">Écrire un message au Défenseur des droits</a>
            </li>
            <li>
              <a href="https://www.defenseurdesdroits.fr/saisir/delegues" title="Contacter le délégué du Défenseur des droits dans votre région - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer"
                >Contacter le délégué du Défenseur des droits dans votre région</a
              >
            </li>
            <li>Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) Défenseur des droits Libre réponse 71120 75342 Paris CEDEX 07</li>
          </ul>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Accessibilite'
};
</script>
